.case-study-landing {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $mobile-image-slide-height;

  &__content {
    display: flex;
    flex-direction: column;
    text-align: center;
    a {
      margin-top: .3rem;
      font-family: KramerFutura;
      font-weight: 200;
      // color: #fff;
      font-size: .7rem;
      text-decoration: none;
    }
  }

  &__company-logo img {
    width: 32vw;
    max-width: 600px;
  }

  &__company-logo.wide img {
    width: 53vw;
    max-width: none;
  }
}

@include respond(laptop) {
  .case-study-landing {
    height: 100vh;
    &__content {

      a {
        margin-top: 5.8rem;
        font-size: 1.75rem;
        line-height: 2rem;
        text-decoration: underline;
      }
    }
  }
}

