*,
*::after,
*::before, {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines the value of 1 rem. Standard font size is 16px. We want a base10 system. 16 * 62.5 = 10
  font-size: 62.5%;

  @include respond(little-phone) {
    font-size: 55%;
  }

  @include respond(tab-portrait) {
    font-size: 62.5%;
  }

  @include respond(tab-landscape) {
    font-size: 62.5%;
  }

  @include respond(laptop) {
    font-size: 62.5%;
  }

  @include respond(big-desktop) {
    font-size: 70%;
  }

  @include respond(giant-desktop) {
    font-size: 80%;
  }
}

@include respond(big-desktop) {
  font-size: 75%;

  .case-study-desktop__text-slide {
    font-size: 65%;
  }
}

body {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
