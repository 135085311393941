.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.fp-tableCell {
  display: flex;
}

.slide-container--text .fp-tableCell {
    align-items: center;
}

.slide-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.slide-container--text {
  background-color: white;
}

//sets div height to screen height
// flex-container to center vertical alignment

.slide {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.case-study-slide {
  min-height: 57vw;
}

@include respond(laptop) {

  .slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .case-study-slide {
    height: 100vh;
    min-height: auto;
  }
}
