.fonts__container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.kf {
  font-family: KramerFutura;
}

.kf_light {
  font-family: KramerFuturaLight;
}

.kf_book {
  font-family: KramerFuturaBook;
}

.kf_bold {
  font-family: KramerFuturaBold;
}

.ss_light {
  font-family: SilkSerifLight;
}

.fonts {
  margin-bottom: 5rem;
  font-size: 3rem;
  // font-weight: 100;
}
