// flex element that aligns center within a slide.
// flex container for the text content within the slide

.text-slide {
  // max-height: calc(100vh - #{$mobile-header-height});
  padding-top: 5rem;
  padding-bottom: 5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__right-column {
    width: 81%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__heading-primary {
    font-family: KramerFuturaLight;
    font-weight: normal;
  }

  &__heading-secondary {
    font-family: SilkSerifLight;
    font-weight: 100;
    margin-top: 7%;
    font-size: 3.6rem;

  }

  &__copy {
    margin-top: 5%;
    font-family: KramerFuturaLight;
    font-size: 1.5rem;
    line-height: 3rem;
    width: 76%;
  }

  &__lists-container {
    margin-top: 12%;
  }

  &__single-list-container {
    margin-top: 1rem;
  }

  &__list-header {
    font-family: SilkSerifLight;
    font-weight: 200;
    font-size: 3rem;
  }

  &__list {
    font-family: KramerFuturaLight;
    list-style-type: none;
    margin-top: 2%;
  }

  &__clients-container {
    margin-top: -1rem;
    display: flex;
    flex-direction: column;
  }

  &__clients-header {
    flex: 1;
    margin-top:6%;
    font-family: SilkSerifLight;
    font-weight: 200;
    font-size: 4.7rem;
  }

  &__client-list {
    display: flex;
    justify-content: space-around;
  }

  ul,
  &__list {
    margin-top: 6%;
    font-family: KramerFuturaLight;
    font-size: 1.2rem;
    line-height: 1.9rem;
    list-style-type: none;
  }
}

@include respond(tab-portrait) {
  
}

// @media (min-width: #{$medium-break-point}) {
@include respond(laptop) {

  .text-slide {
    max-height: calc(100vh - #{$header-height});
    margin-top: $header-height;
    padding-top: 0;
    padding-bottom: 0;
    min-height: auto;
    display: flex;
    flex-direction: row;
    width: 95vw;
    max-width: 136.6rem;
    text-align: left;
    align-items: flex-start;

    &__left-column {
      width: 40%;
    }

    &__right-column {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__heading-primary {
      font-size: 3.1rem;
      margin-left: 8.75rem;
    }

    &__heading-secondary {
      font-size: 9rem;
      line-height: 9.2rem;
      margin-top: -1.7rem;
      max-width: 76rem;

      &-wide {
        max-width: 69rem;
        letter-spacing: -4px;
      }
    }

    &__copy {
      font-size: 2.6rem;
      line-height: 4.6rem;
      margin-top: 2rem;
      width: auto;
      max-width: 71.8rem;
    }

    &__lists-container {
      margin-top: -1.8rem;
      column-count: 2;
    }

    // width ensures "& Design" wraps to new line
    &__single-list-container {
      margin-top: 1rem;
      margin-right: 6%;
      display: inline-block;
    }

    &__list-header {
      font-size: 3.8rem;
    }

    // width ensures text "Execution & Rollouts" wraps to new line
    &__list {
      margin-top: 1.6rem;
      margin-left: 2.7rem;
      font-size: 1.4rem;
      line-height: 2.3rem;
      width: 19.2rem;
    }

    &__clients-container {
      width: 100%;
      margin-top: -1rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around;
    }

    &__clients-header {
      font-size: 4.2rem;
      margin-top: .8rem;
    }

    &__client-list {
      display: flex;
      justify-content: space-around;
      flex: 2;
    }

    ul,
    &__list {
      list-style-type: none;
      font-family: KramerFuturaLight;
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      font-size: 1.4rem;
      line-height: 2.3rem;
    }
  }
}
