//600px-900px: Tablet (Portrait)
//900px-1200px: Tablet (Landscape)
//1200px-1800px: Laptop/Monitor
//1800px+: Big Desktop

@mixin respond($breakpoint) {

  @if $breakpoint == little-phone {
    @media (max-width: 320px) { @content };
  }

  @if $breakpoint == tab-portrait {
    @media (min-width: 600px) { @content };
  }

  @if $breakpoint == tab-landscape {
    @media (min-width: 900px) { @content };
  }

  @if $breakpoint == laptop {
    @media (min-width: 1200px) { @content };
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 1500px) { @content };
  }

  @if $breakpoint == giant-desktop {
    @media (min-width: 1800px) { @content };
  }
}
