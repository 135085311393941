.image-sequence-slide {
  position: relative;

  &-container {
    cursor: pointer;
  }

  img {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;

    &.hidden {
      display: none;
    }
  }
}

@include respond(laptop) {
  .image-sequence-slide {
    img {
      object-fit: cover;
    }
  }
}
