.navigation {
  box-sizing: content-box;
  padding: 2rem;
  width: $mobile-hamburger-icon-width;
  margin-right: calc(#{$mobile-logo-margin} - 2rem;);
  cursor: pointer;

  &__icon {
    position: relative;
    z-index: 3000;
    margin-top: 1rem;

    &,
    &::before,
    &::after {
      width: 100%;
      height: 2px;
      background-color: #000;
      display: inline-block;
    }

    &::before,
    &::after {
      content:"";
      position: absolute;
      left: 0;
      transition: all .4s;
    }

    &::before { top: calc(#{$mobile-hamburger-icon-height} * -1);}
    &::after { top: $mobile-hamburger-icon-height;}

  }

   &__icon.hamburger-close-icon {
     background: transparent;

    &::before {
      top: 0;
      background-color: #fff;
      transform: rotate(135deg)
    }

    &::after {
      top: 0;
      background-color: #fff;
      transform: rotate(-135deg)
    }
  }

  &__nav {
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1500;
    opacity: 0;
    width: 0;
    transition: all .2s;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__nav-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 80%;
    text-align: center;
  }

  &__list {
    list-style: none;
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      font-family: SilkSerifLight;
      color: white;
      font-size: 5rem;
      text-decoration: none;
      transition: all .2s;
    }

    &:hover,
    &:active {
      transform: translateX(.5rem);
    }
  }

  &__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: KramerFuturaBook;
    font-size: 1.9rem;
    line-height: 2.4rem;
    color: #fff;
    margin-top: 12%;
  }

  &__address-container {

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  &__email-address-container {
    display: flex;
    flex-direction: column;
    a {
      font-family: KramerFuturaBook;
      font-size: 1.9rem;
      line-height: 2.4rem;
      text-decoration: none;
      color: #fff;
    }
  }

  &__social-media-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    a {
      font-family: KramerFuturaBook;
      font-size: 1.9rem;
      text-decoration: underline;
      color: #fff;
    }
  }
}

@include respond(tab-portrait) {
  .navigation {
    width: $tablet-portrait-hamburger-icon-width;
    &__icon {
      &::before {
        top: calc(#{$tablet-portrait-hamburger-icon-height}* -1) ;
      }
      &::after {
        top: $tablet-portrait-hamburger-icon-height;
      }
    }
  }
}

// @include respond(tab-landscape) {
//   .navigation {
//     width: $tablet-landscape-hamburger-icon-width;
//     &__icon {
//       &::before {
//         top: calc(#{$tablet-landscape-hamburger-icon-height}* -1) ;
//       }
//       &::after {
//         top: $tablet-landscape-hamburger-icon-height;
//       }
//     }
//   }
// }



@include respond(laptop) {
  .navigation {
    width: $desktop-hamburger-icon-width;
    margin-right: 8rem;
    margin-bottom: -2rem;


    &__icon {

      &::before { top: calc(#{$desktop-hamburger-icon-height} * -1);}
      &::after { top: $desktop-hamburger-icon-height;}
    }

    &__nav-container {
      height: auto;
      width: 80%;
      max-width: 80rem;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      text-align: initial;
    }

    &__right {
      margin-top: 0;
      flex: initial;
      justify-content: space-between;
    }

    &__link {
      &:link,
      &:visited {
        font-size: 7rem;
      }
    }

    &__address-container {
      margin-top: 2rem;
    }

    &__email-address-container {

      a {
        font-size: 1.9rem;
      }
    }

    &__social-media-container {
      justify-content: flex-start;
      a {
        font-size: 1.9rem;
        margin-right: 9.7rem;
      }
    }
  }
}

.icon_white {
  &,
  &::before,
  &::after {

    background-color: #fff;
  }
}

.menu-open {
  opacity: 1;
  width: 100%;
}

@media (max-height: 520px) {
  .navigation {

    &__nav-container {
      height: 80%;
    }
  }
}
