.img-slide {
  flex: 1;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: KramerFutura;
  // color: #fff;
  padding-right: 5%;
  padding-bottom: 4%;
  position: relative;
}

.double-img-slide {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  &__img {
    background-size: cover;
    background-position: center;
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-family: KramerFutura;
    // color: #000;
    padding-right: 5%;
    padding-bottom: 4%;
  }
}

@include respond(laptop) {
  .img-slide {
    flex: 1;
    font-size: 2rem;
  }

  .double-img-slide {
    flex-direction: row;

    &__img {
      font-size: 2rem;
    }
  }
}


.black-text, .black-text a  {
  color: #000;
}

.white-text, .white-text a {
  color: #fff;
}
