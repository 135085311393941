.header-container {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  animation: fadein 2s;
  -webkit-animation: fadein 2s;

  &.hide {
		display: none;
	}
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@keyframes slidein {
  0% {
    transform: translateY(calc(#{$mobile-header-height} * -1));
  }

  100% {
    transform: translate(0);
  }
}

.header {
  height: $mobile-header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo-box {
    margin-top: .2rem;
    margin-left: $mobile-logo-margin;
  }

  &__logo {
    height: $mobile-logo-height;
    max-height: 1.8rem;
  }
}

.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#fff, .8);
  animation: slidein;
  animation-duration: 500ms;
  // opacity: .5;
}

@include respond(laptop) {
  .header {
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &__logo-box {
      margin-top: 0;
      margin-left: 10rem;
    }

    &__logo {
      height: 1.8rem;
    }
  }

  .mobile-header {
    display: none;
  }
}

@media (max-width: 1200px) {
  .header-container.hide-on-mobile {
    display: none;
  }
}
