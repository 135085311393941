.parallax {
  z-index: -100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;

  &__slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip: rect(100vh, 100vw, 100vh, 0vw);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@include respond(laptop) {
  .parallax {
    display: block;
  }

  .case-study-landing {
    background-image: none !important;
  }

  .slide-container .image-sequence-slide {
    display: none;
  }

  .img-slide {
    background-image: none !important;
  }

  .slide-container .double-img-slide__img {
    background-image: none !important;
  }
}
