.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#231F20, 0.95);
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__hidden {
    display: none;
  }

  &__video {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 52.81%;
    margin: 0 5%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
