.container {

  &__icon-container {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3000;
    height: 6vh;
    width: 15%;

    display: flex;
    align-items: center;
    justify-content: center;


    cursor: pointer;

    animation: fadein 2s;
    -webkit-animation: fadein 2s;

    img {
      height: 2vh;
    }

    &__hide {
      display: none;
    }
  }
}

.move-arrow {
  animation: move-arrow;
  animation-duration: 2s;
}

@keyframes move-arrow {
  0% {
    transform: translateX(100vw);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    transform: translate(0);
  }

}


@include respond(laptop) {
  .container {

    &__icon-container {
      width: 5%;
    }
  }
}
