////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: KramerFuturaLight;
  src: url('../../assets/fonts/kramer_futura/KramerFutura-Light.ttf');
}

@font-face {
  font-family: KramerFutura;
  src: url('../../assets/fonts/kramer_futura/KramerFutura.ttf');
}

@font-face {
  font-family: KramerFuturaBook;
  src: url('../../assets/fonts/kramer_futura/KramerFutura-Book.ttf');
}

////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: KramerFuturaHeavy;
  src: url('../../assets/fonts/kramer_futura/KramerFutura-Heavy.ttf');
}
@font-face {
  font-family: KramerFuturaBold;
  src: url('../../assets/fonts/kramer_futura/KramerFuturaLT-Bold.ttf');
}
@font-face {
  font-family: KramerFuturaExtraBold;
  src: url('../../assets/fonts/kramer_futura/KramerFutura-ExtraBold.ttf');
}

/////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: SilkSerifLight;
  src: url('../../assets/fonts/silk/SilkType-SilkSerif-Light-copy.otf');
}
