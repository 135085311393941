.case-study {
  width: 100vw;
}

.case-study-mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__heading {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 7vw;
    margin-bottom: 9vw;
    text-align: center;

    &.logo {

      &.joe-fresh {
        width: 43%;

        img {
          width: 100%;
        }
      }
    }

    h1 {
      font-family: SilkSerifLight;
      font-weight: 100;
      font-size: 5rem;
      line-height: 5rem;
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10vw;

      li {
        font-family: KramerFuturaLight;
        font-size: 1.05rem;
        border-right: 1px #000 solid;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 10px;
      }

      li:last-child {
        border: none;
      }

      li a, a:visited, a:active {
        color: #000;
        text-decoration: none;
      }
    }
  }

  &__text-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 67%;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__section {
      padding: 5%;
    }

    &__header {
      margin-top: 8vw;
      font-family: KramerFuturaBook;
      font-size: 1.2rem;
      font-weight: 200;
    }
    &__subheader {
      margin-top: 3vw;
      font-family: SilkSerifLight;
      font-size: 2.4rem;
      line-height: 2.4rem;
      font-weight: 200;
      text-align: center;
    }

    &__copy {
      margin-top: 2vw;
      text-align: center;
      font-family: KramerFuturaLight;
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: center;
    }
  }

  &__img-slide {
    width: 100%;

    img {
      display: block;
      width: 100%;
    }

    &.forty-percent {
      width: 40%;
      padding: 5% 0;
    }
  }

  &__video-thumbnail {
    padding: 10%;
    text-align: center;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  &__two-img-slide {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__single-image {
      width: 50%;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  &__last-row {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    &__single-image {
      width: 49%;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: KramerFuturaLight;
    font-size: 2.4rem;
    height: 10vh;
    padding: 0 5%;


    a {
      text-decoration: none;
      color: #000;
    }
  }
}

.case-study-desktop {
  display: none;
  flex-direction: column;
  width: 100vw;

  &__columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    width: 100%;
  }

  &__column {
    width: 49.5%;
  }

  &__row {
    height: 100vh;
  }

  &__row.sticky {
    height: 200vh;
  }

  &__content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sticky &__content {
    position: sticky;
    top: 0;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;

    &__logo {
      &__joe-fresh {
        width: 60%;
        margin-bottom: 10%;

        img {
          width: 100%;
        }
      }
    }

    h1 {
      font-family: SilkSerifLight;
      font-weight: 100;
      font-size: 7.6rem;
      line-height: 7.6rem;
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 12%;

      li {
        font-family: KramerFuturaLight;
        font-size: 1.6rem;
        border-right: 1px #000 solid;
        padding-left: 11px;
        padding-right: 11px;
        margin-bottom: 10px;
      }

      li:last-child {
        border: none;
      }

      li a, a:visited, a:active {
        color: #000;
        text-decoration: none;
      }
    }
    &__links {

    }
  }

  &__text-slide {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 100vh;
    width: 58%;
    padding-top: 10%;
    padding-bottom: 10%;

    &__section {
      // padding: 20%;
    }

    &__header {
      font-family: KramerFuturaLight;
      font-size: 1.8rem;
      font-weight: 200;
    }
    &__subheader {
      // margin-top: 5%;
      font-family: SilkSerifLight;
      font-size: 3.4rem;
      line-height: 3.4rem;
      font-weight: 200;
      text-align: center;
    }

    &__copy {
      margin-top: 5%;
      text-align: center;
      font-family: KramerFuturaLight;
      font-size: 1.7rem;
      text-align: center;
      line-height: 2.3rem;
    }
  }

  &__img-slide {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
      object-fit: cover;
    }

    &.forty-percent {
      width: 40%;
      height: initial;
    }

    &.pin-left img {
      object-position: left center;
    }

    &.pin-right img {
      object-position: right center;
    }
  }

  &__video-thumbnail {
    width: 100%;
    text-align: center;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 80%;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__hr {
      height: 1rem;
    }

    &__thumbnails {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__thumbnail {
      width: 33%;
      position: relative;

      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #222;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: KramerFutura;
        font-size: 2.6rem;
        text-align: center;

        p {
          font-size: 1.5rem;
          font-family: KramerFuturaLight;
        }

        &:hover {
          opacity: .9;
          cursor: pointer;
        }

        &__text {
          color: #fff;
        }
      }

      a {
        display: inline;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }
}


@include respond (tab-portrait) {
  .case-study-mobile {

    &__text-slide__subheader {
      font-size: 3.9rem;
      line-height: 4rem;
    }
    &__text-slide__copy {
      font-size: 2.2rem;
      line-height: 3rem;
    }
  }
}

@include respond (tab-landscape) {
  .case-study-mobile {

    &__text-slide__subheader {
      font-size: 5rem;
      line-height: 6rem;
    }
    &__text-slide__copy {
      font-size: 3rem;
      line-height: 4rem;
    }
  }
}
@include respond(laptop) {
  .case-study-mobile {
    display: none;
  }

  .case-study-desktop {
    display: block;
  }
}
