//mobile
$mobile-header-height: calc(100vw / 10);
$mobile-logo-height: calc(#{$mobile-header-height} * .25);
$mobile-logo-margin: calc(100vw * .06);



$mobile-image-slide-height: 100vw * .57;


//desktop
$header-height: 7rem;
$slide-height: 100vh;


//hamburger-menu-icon
  // width
  $mobile-hamburger-icon-width: 4.5vw;
  $tablet-portrait-hamburger-icon-width: 2rem;
  $tablet-landscape-hamburger-icon-width: 2.5rem;
  $desktop-hamburger-icon-width: 3rem;

  //height
  $mobile-hamburger-icon-height: .4rem;
  $tablet-portrait-hamburger-icon-height: .5rem;
  $tablet-landscape-hamburger-icon-height: .6rem;
  $desktop-hamburger-icon-height: .8rem;
