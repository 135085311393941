.holiday {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  font-family: SilkSerifLight;
  text-align: center;
  align-items: center;
  position: relative;

  &__landing {
    width: 100vw;
    min-height: 100vh;
    background-image: url('/dist/images/holiday/holiday.jpg');
    background-size: cover;
    background-position: center top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-family: SilkSerifLight;
    cursor: pointer;

    &__headline {
      font-weight: 400;
      display: block;
      color: #cf2630;
      margin-left: 3rem;
    }

    &__we {
      font-size: 10.3rem;
      display: block;
    }

    &__know {
      margin-top: -3.7rem;
      margin-left: 1rem;
      font-size: 4.7rem;
      display: block;
    }

    &__want {
      margin-top: -6rem;
      margin-right: 6.4rem;
      font-size: 12.6rem;
      display: block;
      text-align: right;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 500px;
  }

  &__left {
    min-height: 50vh;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .headline {
    font-weight: 400;
    display: block;
    color: #cf2630;

    &__we {
      font-size: 10rem;
      display: block;
    }

    &__got {
      margin-top: -1.7rem;
      font-size: 3.7rem;
      display: block;
    }

    &__need {
      margin-top: -5rem;
      font-size: 14rem;
      display: block;
    }
  }

  p.subheading {
    font-family: KramerFuturaLight;
    font-size: 1.7rem;
    padding: 0 7rem;
  }

  p.share {
    display: block;
    font-family: KramerFuturaLight;
    font-size: 1.2rem;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    margin: 1rem auto;
  }

  a:link, a:hover, a:active, a:visited {
    font-family: KramerFuturaLight;
    font-size: 3rem;
    color: #000;
    text-decoration: none;
  }

  &__right {
    padding: 1rem;
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__gift {
    background-size: cover;
    width: 11rem;
    height: 11rem;
    cursor: pointer;

    &__gift-1 {
      background-image: url('/dist/images/holiday/white-box-1.jpg');
      &:hover {
        background-image: url('/dist/images/holiday/red-box-1.jpg');
      }
    }

    &__gift-2 {
      background-image: url('/dist/images/holiday/white-box-2.jpg');
      &:hover {
        background-image: url('/dist/images/holiday/red-box-2.jpg');
      }
    }

    .num {
      display: none;
      font-size: 9rem;
      color: #fff;
      cursor: pointer;
    }

    &:hover {
      .num {
        display: block;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    background-color: rgba(#231F20, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    animation: fadein;
    animation-duration: .3s;

    &__hidden {
      display: none;
    }

    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;;
      width: 1.7rem;

      img {
        width: 100%;
        padding: 0;
        cursor: pointer;
      }
    }

    &__content {

      width: 80%;
      min-width: 32rem;
      max-width: 120rem;
      max-height: 90%;
      background-color: #fff;
      display: block;
      flex-direction: column;
      line-height: 1.5em;
      position: relative;
      padding: 3rem;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &__left {
        flex: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__img-links {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
          margin-top: 2.5rem;
          z-index: 1000;
        }

        &__img-link {
          height: 1rem;
          width: 1rem;
          border-radius: 50%;
          border: 1px solid #cf2630;
          margin-right: 1rem;
          cursor: pointer;


          &.current {
            background-color: #cf2630;
          }
        }

        &__img-link:last-child {
          margin-right: 0;
        }
      }


      &__img-window {

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__text-block {
        flex: 30%;
        background-color: white;
        text-align: left;
        // padding: 0 1rem;
        // padding-left: 3.3rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1.5rem;
        padding-bottom: 5rem;

        h1 {
          font-family: SilkSerifLight;
          font-size: 4.7rem;
          line-height: 4.7rem;
          color: #cf2630;
          font-weight: 400;
          margin-bottom: 2rem;
        }

        p.address {
          font-family: KramerFuturaLight;
          font-size: 1.6rem;
          line-height: 2rem;
          margin-bottom: 2rem;
        }

        p.gift-and-price {
          font-family: KramerFuturaBook;
          font-size: 1.4rem;
          text-decoration: none;
          color: #333;
          margin-top: 2.5rem;
          margin-bottom: 1rem;
        }

        &__scroll-container {
          flex-grow: 1;
          overflow: scroll;
          width: 100%;
          position: relative;
          padding-bottom: 1rem;
        }

        &__scroll-body {
          position: static;
          top: 0;
        }

        p.subheader {
          font-size: 1.8rem;
          line-height: 2.3rem;
          font-family: KramerFuturaHeavy;
          margin-bottom: 1rem;
        }

        .copy p {
          font-size: 1.8rem;
          line-height: 2.4rem;
          font-family: KramerFuturaLight;
          margin-bottom: 1rem;
        }

        & > a:link, a:hover, a:active, a:visited {
          font-family: KramerFuturaHeavy;
          font-size: 1.8rem;
          text-decoration: none;
          color: #cf2630;
        }
      }
    }
  }

  &__share-modal {

    display: flex;
    justify-content: center;
    z-index: 1001;
    animation: fadein;
    animation-duration: 1s;
    text-align: center;
    width: 100%;

    &__hidden {
      display: none;
    }

    &__content {
      width: 100%;
      height: 50%;
      background-color: #fff;
      font-size: 3em;
      text-align: center;


      a {
        display: inline;
      }
    }
  }
}

.fab:hover, .fas:hover {
  color: #cf2630;
  cursor: pointer;
}

@include respond (tab-portrait) {

  .holiday {

    &__container {
      max-width: 72rem;
      align-items: center;
    }

    &__left {
      min-height: 60vh;
      max-width: 37rem;
    }

    p.subheading {
      font-size: 2.3rem;
      padding: 0 4rem;
    }

    p.share {
      font-size: 1.5rem;
    }

    &__right {
      display: flex;
      justify-content: center;
      padding: 2rem;
    }

    &__gift {
      width: 14rem;
      height: 14rem;

      .num {
        font-size: 11rem;
      }
    }
  }
}

@include respond (tab-landscape) {

  .holiday {
    align-items: center;
    justify-content: center;

    &__landing {
      padding-left: 5rem;
    }


    &__container {
      align-items: center;
    }

    &__left {
      justify-content: space-around;
      max-width: 45rem;
    }

    p.subheading {
      margin-top: 0;
    }

    &__gift {
      width:15rem;
      height: 15rem;
    }

    &__overlay {
      &__content {
        display: flex;
        flex-direction: row;
        padding: 4rem;
        padding-right: 5rem;

        &__text-block {
          padding-left: 3.3rem;
          padding-bottom: 0;

          &__scroll-body {
            position: absolute;
            top: 0;
          }

          h1 {
            margin-top: -1.8rem;
          }
        }
      }
    }
  }
}


@include respond (laptop) {

  .holiday {
    justify-content: center;

    &__container {
      max-width: 100rem;
      flex-direction: row;
    }

    &__left {
      min-height: 50vh;
    }

    p.subheading {
      font-size: 2.1rem;
      padding: 0 1.1rem;
    }

    &__right {
      flex: 100%;
      max-width: 100%;
      padding: 2rem;
    }

    &__gift {
      width: 16rem;
      height: 16rem;

      .num {
        font-size: 13rem;
      }
    }
  }
}

.tns-outer {
  position: relative;

  .tns-controls {
    position: absolute;
    left: 0;
    top: 0;
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: stretch;
    z-index: 1;
    outline: none;

    button {
      flex: 1;
      background: transparent;
      border:none;
      color: transparent;
      outline: none;
      user-select: none;
    }
  }

  .tns-nav {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    outline: none;
    button {
      width: 1rem;
      height: 1rem;
      color: #cf2630;
      background: #fff;
      border-radius: 50%;
      margin: 0 .5rem;
      border: 1px solid #cf2630;
      outline: none;

      &.tns-nav-active {
        background: #cf2630;
        outline: none;
      }
    }
  }
}

#urlCopied {
  display: none;
  border: 1px solid black;
  padding: .5rem;
  margin: 1rem auto;
}
