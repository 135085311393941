.footer {
  height: 6vh;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // border-top: 1px solid #000;
  background-color: white;

  &__content {
    font-family: KramerFutura;
    width: 79%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .logo {
      img {
        height: 1vw;
        min-height: .7rem;
      }
    }

    a, a:link, a:visited, a:active {
      color: #000;
      text-decoration: none;
    }
  }
}

.desktop-only {
  display: none;
}

@include respond(laptop) {
  .footer {
    &__content {
      width: 90%;
      justify-content: space-around;
    }
  }

  .desktop-only {
    display: block;
  }
}
